import React from 'react'
import { Col, Form, Image, Button } from 'react-bootstrap'
import { Alert, AlertTitle } from '@material-ui/lab'
import LocalPrintshopIcon from '@material-ui/icons/LocalPrintshop'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
const nameSeller = localStorage.getItem('name')
function CorpoPedidoUnic({ data }) {
  return (
    <Container component={Paper} elevation={6}>
      <br />
      <Image
        src="https://www.tbssurfboards.com.br/wp-content/uploads/2019/11/Logo_black-TBS.png.png"
        width="370px"
        rounded
      />
      <br />
      <Alert
        severity="success"
        color="success"
        action={
          <LocalPrintshopIcon
            onClick={() => {
              window.print()
            }}
            fontSize="small"
          />
        }
      >
        <AlertTitle>Pedido numero {data.id}</AlertTitle>
        Vendedor: {nameSeller}
      </Alert>
      <br />
      <div className="Formulario-pedido">
        <Form>
          <br />
          <Form.Group controlId="exampleForm.ControlInput1">
            <Container component={Paper} elevation={1}>
              <Form>
                <Form.Row className="align-items-center">
                  <Col xs={3}>
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      value={data.name_user}
                      type="text"
                      placeholder="José.."
                    />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>Telefone</Form.Label>
                    <Form.Control
                      value={data.telefone}
                      type="text"
                      placeholder="(41) 3333-3333"
                    />
                  </Col>

                  <Col xs="auto">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      value={data.email}
                      type="text"
                      placeholder="name@example.com"
                    />
                  </Col>
                </Form.Row>
              </Form>
              <br />
            </Container>
            <br />

            <Container component={Paper} elevation={1}>
              <br />
              <Form.Label>Modelo</Form.Label>
              <Form.Control
                value={data.modelo}
                type="text"
                placeholder="Modelo escolhido"
              />
              <Form.Label>Tamanho</Form.Label>
              <Form.Control
                value={data.tamanho}
                type="text"
                placeholder="Tamanho"
              />
              <Form.Label>Rabeta</Form.Label>
              <Form.Control
                value={data.rabeta}
                type="text"
                placeholder="Rabeta"
              />
              <Form.Label>Laminação</Form.Label>
              <Form.Control
                value={data.laminacao}
                type="text"
                placeholder="Laminação"
              />
              <Form.Label>Acabamento</Form.Label>
              <Form.Control
                value={data.acabamento}
                type="text"
                placeholder="Acabamento escolhido"
              />
              <Form.Label>Quilha</Form.Label>
              <Form.Control
                value={data.quilha}
                type="text"
                placeholder="Quilha escolhida"
              />

              <Form>
                <Form.Row className="align-items-center">
                  <Col xs={3}>
                    <Form.Label>Peso</Form.Label>
                    <Form.Control value={data.peso} type="text" />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>Altura</Form.Label>
                    <Form.Control value={data.altura} type="text" />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>Calçado</Form.Label>
                    <Form.Control value={data.calcado} type="text" />
                  </Col>
                  <Col xs="auto">
                    <Form.Label>Pintura</Form.Label>
                    <Form.Control as="select" value={data.pintura}>
                      <option>Pintura?</option>
                      <option>Sim</option>
                      <option>Não</option>
                    </Form.Control>
                  </Col>
                </Form.Row>
              </Form>
              <br />

              <br />
            </Container>
            <br />
            <Container component={Paper} elevation={2}>
              <br />

              <Form>
                <Form.Row className="align-items-center">
                  <Col>
                    <Form.Label>Preço </Form.Label>
                    <Form.Control value={`R$: ${data.preco}`} />
                  </Col>
                  <Col>
                    <Form.Label>Entrada </Form.Label>
                    <Form.Control value={`R$: ${data.entrada}`} />
                  </Col>
                  <Col>
                    <Form.Label>Saldo </Form.Label>
                    <Form.Control value={`R$: ${data.saldo}`} />
                  </Col>
                </Form.Row>
              </Form>
              <Form>
                <Form.Row>
                  <Col>
                    <Form.Label>Data da solicitação</Form.Label>
                    <Form.Control value={data.dataAber} />
                  </Col>
                  <Col>
                    <Form.Label>Data da entrega </Form.Label>
                    <Form.Control value={data.dataEntr} />
                  </Col>
                </Form.Row>
              </Form>
              <Form.Label>Observações</Form.Label>
              <Form.Control
                value={data.observacao}
                as="textarea"
                rows={6}
                placeholder="Observações"
              />
              <Button
                variant="link"
                href={`https://wa.me/${data.telefone}?text=Olá ${data.name_user} agora você pode ver seu pedido atravez deste link! http://toorbkp.ddns.net/cliente/pedido?id=${data.pedidoHash}`}
              >
                Enviar via WhatsApp
                <WhatsAppIcon />
              </Button>

              <br />

              <br />
            </Container>
          </Form.Group>
        </Form>
      </div>
      <br />
    </Container>
  )
}

export default CorpoPedidoUnic
