import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import ToastAnimated, { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'
import './styles.css'
import api from '../../services/api'

import history from '../../history'

function RecoverPass() {
  const [passwordmain, setPasswordmain] = useState('')
  const [password, setPassword] = useState('')
  const { search } = useLocation()
  const { token } = queryString.parse(search)
  const { email } = queryString.parse(search)

  function handleBack() {
    history.push('/login')
  }

  async function passChange(event) {
    event.preventDefault()
    if (!passwordmain)
      return showToast({
        type: 'error',
        message: 'Preencha sua senha',
        pauseOnFocusLoss: true,
      })
    if (passwordmain != password)
      return showToast({
        type: 'error',
        message: 'As senhas são diferentes, tente novamente',
        pauseOnFocusLoss: true,
      })
    try {
      await api.post('/recoverpass', {
        password: passwordmain,
        token: token,
        email: email,
      })

      return showToast({
        type: 'success',
        message: 'Senha alterada',
        pauseOnFocusLoss: true,
      })
    } catch (err) {
      return showToast({
        type: 'error',
        message:
          'Não foi possivel trocar sua senha, contate seu administrador do site.',
        pauseOnFocusLoss: true,
      })
    }
  }
  return (
    <div className="MainClass">
      <div className="loginInput">
        <form>
          <br />
          <br />
          <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <img
            src="https://www.tbssurfboards.com.br/wp-content/uploads/2019/11/Logo_black-TBS.png.png"
            width="400px"
          />
          <br />
          <br />
          <strong>Digite uma nova senha </strong>
          <br />
          <input
            type="password"
            className="form-control"
            placeholder="Digite uma senha"
            value={passwordmain}
            onChange={(event) => {
              setPasswordmain(event.target.value)
            }}
          />
          <br />
          <strong>Digite novamente a senha </strong>
          <br />
          <input
            type="password"
            className="form-control"
            placeholder="Repita a senha digitada"
            value={password}
            onChange={(event) => {
              setPassword(event.target.value)
            }}
          />
          <br />
          <br />
          <ToastAnimated />
          <button type="submit" className="btn-block" onClick={passChange}>
            Alterar a senha
          </button>
        </form>
      </div>
    </div>
  )
}

export default RecoverPass
