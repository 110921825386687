import { useState, useEffect } from 'react'
import ToastAnimated, { showToast } from '../../ui-lib/toast'
import { Button } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import './styles.css'
import api from '../../services/api'

import history from '../../history'

function RecoverPass() {
  const [email, setEmail] = useState('')

  function handleBack() {
    history.push('/login')
  }

  async function handleRecover(event) {
    event.preventDefault()
    if (!email)
      return showToast({
        type: 'error',
        message: 'Preencha seu email',
        pauseOnFocusLoss: true,
      })

    try {
      await api.post('/forgot_password', {
        email,
      })

      showToast({
        type: 'success',
        message:
          'Verifique seu email, e siga as instruçoes para alterar sua senha',
        pauseOnFocusLoss: true,
      })
    } catch (err) {
      return showToast({
        type: 'error',
        message: 'Verifique seu email e tente novamentel',
        pauseOnFocusLoss: true,
      })
    }
  }
  return (
    <div className="MainClass">
      <div className="loginInput">
        <form>
          <br />
          <br />
          <br />
          <br /> <br />
          <br /> <br />
          <br /> <br />
          <img
            src="https://www.tbssurfboards.com.br/wp-content/uploads/2019/11/Logo_black-TBS.png.png"
            width="400px"
          />
          <br />
          <br />
          <strong>Qual Email usado para entrar na sua conta? </strong>
          <br /> <br />
          <input
            type="email"
            className="form-control"
            placeholder="Enter email"
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
          />
          <br />
          <br />
          <ToastAnimated />
          <Button
            type="submit"
            className="btn-block"
            variant="dark"
            onClick={handleRecover}
          >
            Enviar e-mail
          </Button>
          <Button
            type="submit"
            className="btn-block"
            variant="dark"
            onClick={handleBack}
          >
            Voltar
          </Button>
        </form>
      </div>
    </div>
  )
}

export default RecoverPass
