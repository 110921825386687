import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import ToastAnimated, { showToast } from '../../ui-lib/toast'
import 'react-toastify/dist/ReactToastify.css'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import NavBar from '../../components/NavBar/NavBar'
import api from '../../services/api'
import Alert from '@material-ui/lab/Alert'
import 'react-toastify/dist/ReactToastify.css'
function CadastroUsuarios() {
  const [name, setName] = useState('')
  const [pass, setPass] = useState('')
  const [email, setEmail] = useState('')

  async function handleCreateuser() {
    await api
      .post('/singup', {
        name: name,
        email: email,
        password: pass,
        grupo: 'false',
      })
      .then(() => {
        showToast({
          type: 'success',
          message: 'Usuario registrado com sucesso',
        })
      })
      .catch((response) => {
        showToast({
          type: 'error',
          message:
            'Usuario não registrado verifique com o administrador do sistema',
        })
      })
  }
  return (
    <div>
      <NavBar />
      <ToastAnimated />
      <br />
      <br />
      <Container component={Paper} elevation={6}>
        <br />
        <Alert severity="error" color="error">
          Cadastrar um novo usuario (Precisa ser administrador)
        </Alert>
        <br />
        <div className="Formulario-pedido">
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Container component={Paper} elevation={4}>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                  }}
                  type="email"
                  id="e-mail"
                  placeholder="name@example.com"
                />
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  value={name}
                  id="Nome"
                  onChange={(event) => {
                    setName(event.target.value)
                  }}
                  type="text"
                  placeholder="José.."
                />
                <Form.Label>Senha</Form.Label>
                <Form.Control
                  value={pass}
                  id="pass"
                  onChange={(event) => {
                    setPass(event.target.value)
                  }}
                  type="password"
                />
                <br />
                <Button variant="success" onClick={handleCreateuser}>
                  Cadastrar
                </Button>
                <br />
                <br />
              </Container>
            </Form.Group>
          </Form>
        </div>
        <br />
      </Container>
    </div>
  )
}

export default CadastroUsuarios
