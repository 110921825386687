import { useContext, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button } from 'react-bootstrap'
import Switch from '@material-ui/core/Switch'
import ToastAnimated from '../../ui-lib/toast'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import ReCAPTCHA from "react-google-recaptcha";

import './styles.css'

import { Context } from '../../context/AuthContext'

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  )
})

function Login() {
  const {
    HandleLogin,
    setEmail,
    setPassword,
    email,
    password,
    setToken2f,
    token2f,
    borderInputPass,
    borderInput,
    setCpVeri
  } = useContext(Context)
  const [showSenha, setShowSenha] = useState('password')

  function mostrarSenha() {
    if (showSenha === 'password') {
      setShowSenha('textarea')
    } else if (showSenha === 'textarea') {
      setShowSenha('password')
    }
  }
  function VeriCp() {
    setCpVeri(true)
  }

  return (
    <div
      className="MainClass"
      style={{
        backgroundImage: `url(${'https://www.tbssurfboards.com.br/wp-content/uploads/2019/11/miniaturas_home-TBSSURFBOARDS-1140x642.jpg'})`,
        backgroundRepeat: 'repeat',
        backgroundPosition: 'right',
      }}
    >
      <div className="loginInput">
        <br />
        <br />
        <br />
        <br /> <br />
        <Container component={Paper} elevation={5}>
          <br />
          <form>
            <img
              src="https://www.tbssurfboards.com.br/wp-content/uploads/2019/11/Logo_black-TBS.png.png"
              width="350px"
            />
            <br />
            <br />
            <br />
            <label>Email </label>
            <input
              type="email"
              className="form-control"
              placeholder="Coloque aqui seu email"
              style={{ borderColor: borderInput }}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value)
              }}
            />
            <br />
            <label>Senha</label>
            <input
              type={showSenha}
              className="form-control"
              border-color="blue"
              placeholder="Coloque aqui sua senha"
              style={{ borderColor: borderInputPass }}
              value={password}
              onChange={(event) => {
                setPassword(event.target.value)
              }}
            />
            <br />
            <input
              type="checkbox"
              className="custom-control-input"
              id="customCheck1"
            />
            <FormControlLabel
              control={<IOSSwitch name="checkedB" onChange={mostrarSenha} />}
              label="Mostrar senha"
            />
            <br />
            <ReCAPTCHA
              sitekey="6LcN8YwaAAAAAPjgmrG2lP50yHICcZaNBJzi2Uow"
              onChange={VeriCp}
            />
            <br />

            <ToastAnimated />
            <Button
              variant="dark"
              size="lg" block
              onClick={HandleLogin}
            >
              Entrar
            </Button>
            <p className="forgot-password text-right">
              Esqueceu a <a href="/recuperarsenha">senha?</a>
            </p>
          </form>
          <br />
        </Container>
      </div>
    </div>
  )
}

export default Login
