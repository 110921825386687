import { createContext } from 'react'

import useAuth from './hooks/useAuth'

const Context = createContext()

function AuthProvider({ children }) {
    const {
        adminauth,
        authenticated,
        HandleLogin,
        setPassword,
        setEmail,
        email,
        password,
        token2f,
        setToken2f,
        HandleLogout,
        loading,
        borderInputPass,
        borderInput,
        setCpVeri
    } = useAuth()
    return (
        <Context.Provider
            value={{
                adminauth,
                authenticated,
                HandleLogin,
                setPassword,
                setEmail,
                email,
                password,
                token2f,
                setToken2f,
                HandleLogout,
                loading,
                borderInputPass,
                borderInput,
                setCpVeri
            }}
        >
            {children}
        </Context.Provider>
    )
}
export { Context, AuthProvider }
