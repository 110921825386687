import { useState, useEffect } from 'react'
import api from '../../services/api'

import '../Main/Main.css'
import CorpoPedidounico from '../../components/PedidoUnico'
import NavBar from '../../components/NavBar/NavBar'
function MainDashboard() {
  const [body, setBody] = useState([])
  const id = localStorage.getItem('id')

  useEffect(() => {
    ;(async () => {
      api.get('/pedido/unico?id=' + id).then((response) => {
        const result = response.data
        setBody(result)
      })
    })()
  }, [])

  const refreshPage = () => {
    window.location.reload()
  }
  return (
    <div>
      <NavBar />
      <br />
      <br />
      <div>
        <br />
        {body.map((body) => {
          return <CorpoPedidounico key={body.id} data={body} />
        })}
      </div>
    </div>
  )
}

export default MainDashboard
